<template>
  <S2SForm title="Connection Settings">
    <S2SCard>
      <S2SFormGenerator
        ref="form"
        :schema="connectionSettingsSchema"
        :data="formData"
      ></S2SFormGenerator>
      <v-layout row wrap justify-end>
        <v-btn
          @click="onSubmit"
          color="accent"
          class="ma-1"
          :block="$vuetify.breakpoint.xsOnly"
          >Save</v-btn
        >
      </v-layout>
    </S2SCard>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import connectionSettingsSchema from "@/static/config/forms/connection-settings.json";

  export default Vue.extend({
    name: "CustomerSettings",

    props: {
      customerCode: {
        type: String,
      },
    },

    data: function () {
      return {
        connectionSettingsSchema: connectionSettingsSchema,
        formData: {},
      };
    },

    computed: {
      connectionBuyerSettings: function () {
        return this.$store.getters["connections/connectionBuyerSettings"];
      },
      connectionDiscountsAndSettings: function () {
        return this.$store.state.connections.connectionDiscountsAndSettings;
      },
    },
    mounted: function () {
      if (this.$props.customerCode != null)
        this.$store.dispatch("connections/fetchConnectionByOrgCode", {
          code: this.$props.customerCode,
        });
      Object.keys(this.connectionBuyerSettings).forEach((element) => {
        // format data for formGenerator
        this.formData[this.connectionBuyerSettings[element].name] =
          this.connectionBuyerSettings[element].value;
      });
    },

    methods: {
      async onSubmit() {
        const form = this.$refs["form"];
        let allOK = await form.$validator.validateAll();
        if (allOK) {
          try {
            let payload = [];
            Object.keys(form.model).forEach((key, index) => {
              const settingObj = {
                value: form.model[key],
                settingKeyId: this.connectionBuyerSettings[key].id,
              };
              payload.push(settingObj);
            });
            this.$store.dispatch(
              "connections/updateConnectionSettingByConnectionId",
              {
                connectionId: this.connectionDiscountsAndSettings.id,
                body: payload,
              }
            );
          } catch {
            // Placeholders, we don't need to do anything here
          }
        }
      },
    },
  });
</script>
